import * as React from "react";
import img from "../images/axles.jpg";
import a1 from "../images/driveline/ball_joint.png";
import a2 from "../images/driveline/drive_shaft_1.png";
import a3 from "../images/driveline/drive_shaft_2.png";
import b1 from "../images/driveline/drive_shaft_3.png";
import b2 from "../images/driveline/drive_shaft_4.png";
import b3 from "../images/driveline/steering_systems.png";

import { MainLayout } from "../layouts/MainLayout";

// markup
const MilitaryVehicleParts = () => {
  return (
    <MainLayout>
      <section className="section">
        <main className="container">
          <div className="page-content">
            <div className="columns">
              <div className="column">
                <div className="page-title">
                  <h1>AXLES SYSTEMS & DESIGN</h1>
                </div>
                <p className="page-description">
                  We develop complete axle systems, drive lines and their
                  components (including planetary gear systems), independent
                  suspensions and rigid axles - for all type of off-highway
                  applications, basis customer requirements. Our engineering
                  team of experts, possess over 30 years of experience, in the
                  field of vehicle development.
                </p>
                <figure className="service-image">
                  <img src={img} alt="mining-image" />
                </figure>
              </div>
              <div className="column">
                <section className="page-list">
                  <h3 className="title">Services</h3>
                  <ul>
                    <li>
                      <p className="list-title">
                        <span>Axles - Design & Development</span>
                        We design and develop axles for off-highway applications
                        for vehicles ranging from 5 tonnes to 20 tonnes. Our
                        experts ensure that the design is perfectly adapted to
                        the vehicle geometry and its required off-highway
                        applications. This is achieved in co-operation with the
                        diverse sub- module manufacturers, keeping in mind the
                        nature of expected performance and the areas of
                        application.
                      </p>
                    </li>
                    <li>
                      <p className="list-title">
                        <span>Axles</span>
                        We service axles and transmissions for off-highway
                        applications. The portfolio also includes sub-assemblies
                        and special transmissions, as well as lifting parts used
                        in material handling applications.
                      </p>
                      <p className="leading-brand">Leading Brands Handled</p>
                      <span className="brands">AVT,</span>
                      <span className="brands">Kessler Axles,</span>
                      <span className="brands">Dana Axles,</span>
                      <span className="brands">Dana Transmissions,</span>
                      <span className="brands">Carraro Axles,</span>
                      <span className="brands"> Allison Transmissions,</span>
                      <span className="brands">Flender Transmissions</span>
                      <span className="brands">
                        Reggiana Riduttori Sub-Assemblies
                      </span>
                    </li>
                    <p>
                      More details available on{" "}
                      <a href="www.axlecenter.com">www.axlecenter.com</a>
                    </p>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </main>
      </section>
    </MainLayout>
  );
};

export default MilitaryVehicleParts;
